<template lang="pug">
.main-wrapper.tipoCheckup-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Tipo de Checkup</b>

		Dialog(header='Remover tipo de checkup' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o tipo de checkup <b>{{ dialogApagar_data.ds_checkup }}</b>?
			.ta-center.my-4
				Button.p-button-danger(label='Remover' @click='remove()')

		Dialog.dialog-visualizarProcedimentos(header='Visualizar procedimentos' :visible.sync='dialogVisualizar' :modal='true')
			.waitingProcedimentos(v-if='waitingProcedimentos')
				ProgressSpinner(strokeWidth='4')
			div(v-else)
				p.text-title {{ dialogVisualizar_data.ds_checkup }}
				div(v-for='procedimento, idx in dialogVisualizar_data.cd_procedimentos' :key='idx')
					p.text-procedimento • {{ procedimento.ds_procedimento }}

		.ta-right.my-2(v-if='! waiting')
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/tipo-checkup/salvar/0/')")
		
		Panel.mb-2(header='Filtros' :toggleable='true')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-4
					label.form-label Descrição:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_checkup' @keyup='checkEmptyField("ds_checkup")' )
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-4
					label.form-label Observações:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_observacao' @keyup='checkEmptyField("ds_observacao")' )
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-4
					label.form-label Status:
					Dropdown(
						v-model='filters.ie_ativo'
						:options='filtersOptions.ieStatus'
						optionLabel='text'
						optionValue='value'
						dataKey='value'
						placeholder='TODOS'
						@change='applyFilters()'
					)

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			Panel.datatable(header='Lista de tipos de checkup')

				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

				DataTable(:value="list")
					Column(headerStyle='width: 4em' bodyStyle='text-align:center')
						template(#body='props')
							ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
							.status-indicator(v-else :class="{ off: ! props.data.ie_ativo }" @click='alterarStatus(props.data.id)')
								i.jam(:class="{ 'jam-check': props.data.ie_ativo, 'jam-minus': !props.data.ie_ativo }")
					Column(header='Descrição' field='ds_checkup' headerStyle='width: 40%')
					Column(header='Observações' field='ds_observacao' headerStyle='width: 40%')
					Column(header='Ações' headerStyle='width: 20%')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mx-1(
									v-tooltip.top="'Ver procedimentos'"
									icon="jam jam-task-list"
									@click="showProcedimentos(props.data.id)")
								Button.p-button-raised.p-button-rounded.mx-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/tipo-checkup/salvar/${ props.data.id }/`)")
								Button.p-button-raised.p-button-danger.p-button-rounded.mx-1(
									v-tooltip.top="'Remover'"
									icon="jam jam-trash"
									@click="dialogApagar = true; dialogApagar_data = props.data")
				
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.tipoCheckup-listar {
		.dialog-visualizarProcedimentos {
			width: 98%;
			max-width: 640px;
			max-height: 480px;
			.waitingProcedimentos {
				text-align: center;
				margin: 4em 0;
				.p-progress-spinner {
					width: 48px;
					height: auto;
				}
			}
			.text-title {
				text-align: center;
				font-weight: 600;
			}
			.text-procedimento {
				margin: 0;
				font-size: .92em;
			}
		}
		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			transition: 250ms;
			cursor: pointer;
			background-color: #94c860;
			border: 1px solid #84b553;
			&:hover { background-color: #84b553; }
			&.alt {
				background-color: #007ad9;
				border: 1px solid #0e6bb3;
				&:hover { background-color: #0e6bb3; }
			}
			
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
				&.alt {
					background-color: #bbb;
					border: 1px solid #aaaaaa;
					&:hover { background-color: #aaa; }
				}
			}
			.jam {
				color: #fff;
			}
		}
		.waitingStatus {
			width: 25px;
			height: auto;
		}
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import Button from 'primevue/button'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Dialog from 'primevue/dialog'
	import Column from 'primevue/column'
	import Tooltip from 'primevue/tooltip'
	import ProgressSpinner from 'primevue/progressspinner'
	import InputText from 'primevue/inputtext'
	import Dropdown from 'primevue/dropdown'

	import wsConfigs from './../../middleware/configs'
	import { Checkup } from '../../middleware'
	export default {
		components: { ProgressBar, Button, Panel, Paginator, DataTable, 
			Column, Tooltip, Dialog, ProgressSpinner, InputText,
			Dropdown },
		directives: { tooltip: Tooltip },
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'tipo-checkup') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {  },
		data () {
			return {
				list: [],
				waiting: false,
				waitingProcedimentos: false,
				waitingStatus: 0,
				filters: { 
					ds_checkup: null, 
					ds_observacao: null,
					ie_ativo: 'true'
				},
				filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
                    ]
                },
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				dialogApagar: false,
				dialogApagar_data: {},
				dialogVisualizar: false,
				dialogVisualizar_data: {}
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				return Checkup.findAllTipoCheckup(params).then(response => {
					if (response.status == 200) {
						// this.paginator.count = response.data.count
						this.list = response.data
					}
					this.waiting = false
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => {
					if (this.filters[key]) params[key] = this.filters[key]
				})
				this.getList(params)
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			showProcedimentos (id) {
				this.dialogVisualizar = true
				this.waitingProcedimentos = true
				Checkup.findTipoCheckup(id).then(response => {
					this.waitingProcedimentos = false
					if (response.status === 200) this.dialogVisualizar_data = response.data
				})
			},
			remove () {
				this.dialogApagar = false
				this.waiting = true
				Checkup.removeTipoCheckup(this.dialogApagar_data.id).then(response => {
					if (response.status == 200) {
						this.$toast.info('Tipo de checkup removido com sucesso', { duration: 3000 })
						this.applyFilters()
					}
				})
			},
			alterarStatus (id) {
				let checkup = this.list.find(a => a.id === id)
				this.waitingStatus = id
				Checkup.saveTipoCheckup({ id, ie_ativo: ! checkup.ie_ativo }).then(response => {
					this.waitingStatus = 0
					if (response.status === 200) {
						checkup.ie_ativo = ! checkup.ie_ativo
						if (checkup.ie_ativo)
							this.$toast.success('Tipo de check-up habilitado', { duration: 3000 })
						else
							this.$toast.info('Tipo de check-up desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			}
		}
	}
</script>